/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app) => {
		/*
		|
		| Constants
		|-----------
		*/
        let price = $("#gift-value"),
            input = $(".hidden-price input"),
			total = $(".ginput_quantity"),
			inputPrice = $('.ginput_container_total input');

			total.val(1)

		/*$('form').on('submit', function(event) {
			event.preventDefault();
			total.val(1)
		});*/
	}
}
