/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        const toggleBtns = document.querySelectorAll('#header button.toggle-btn')

        function toggleAriaExpanded() {
            const expanded = this.getAttribute('aria-expanded') === 'true'

            toggleBtns.forEach((toggleBtn) => {
                if (toggleBtn !== this) {
                    toggleBtn.setAttribute('aria-expanded', 'false')
                }
            });

            this.setAttribute('aria-expanded', !expanded)

            if (burgerBtn.getAttribute('aria-expanded') === 'true') {
                body.style.overflow = 'hidden'
            } else {
                body.style.overflow = ''
            }
        }

        toggleBtns.forEach((toggleBtn) => {
            toggleBtn.addEventListener('click', toggleAriaExpanded)
        })
    }
}
