/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        let header = $('#header').outerHeight()
        let distance = $('#anchor').offset().top - header; 
        $(window).scroll(function () {
              if ($(window).scrollTop() >= distance) {
                  $('#anchor').addClass("fixed");
                  $('#anchor.fixed').css("top", header);
              } else {
                  $('#anchor').removeClass("fixed");
                  $('#anchor').css("top", 0);
              }
        });
    }
}
