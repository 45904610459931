/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        /**
         * 
         * Input Number
         * 
         */
         $('<div class="quantity-nav"><div class="quantity-button quantity-up"></div><div class="quantity-button quantity-down"></div></div>').insertAfter('.ginput_container_number input');
         $('.ginput_container_number').each(function() {
           var spinner = $(this),
             input = spinner.find('input[type="number"]'),
             btnUp = spinner.find('.quantity-up'),
             btnDown = spinner.find('.quantity-down'),
             min = input.attr('min'),
             max = input.attr('max');
     
           btnUp.click(function() {
            
             var oldValue = parseFloat(input.val());
             console.log('click', oldValue);
             if (oldValue >= max) {
               var newVal = oldValue;
             } else {
               var newVal = oldValue + 1;
             }
             spinner.find("input").val(newVal);
             spinner.find("input").trigger("change");
           });
     
           btnDown.click(function() {
             var oldValue = parseFloat(input.val());
             if (oldValue <= min) {
               var newVal = oldValue;
             } else {
               var newVal = oldValue - 1;
             }
             spinner.find("input").val(newVal);
             spinner.find("input").trigger("change");
           });
     
         });

         const queryString = window.location.search;
         const urlParams = new URLSearchParams(queryString);
         const source = urlParams.get('src')
        if (source) {
          $('textarea').val(source)
        }
        
    }
}
