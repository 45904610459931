/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
import Swiper, { Navigation, Pagination, EffectCoverflow } from 'swiper';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import validate from 'jquery-validation';


gsap.registerPlugin(ScrollTrigger);
//gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';
import Header from '@components/header.js'

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import news from '@pages/news.js';
import animations from '@pages/animations.js';
import contact from '@pages/contact.js';
import giftcard from '@pages/giftcard.js';
import actu from '@pages/actu.js';
import offre from '@pages/offre.js';
import nice from '@pages/nice.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
      'file': animations,
      'dependencies': [app, gsap, ScrollTrigger, Menu, Kira]
    },
	  {
      'file': main,
      'dependencies': [app, gsap, Swiper, Navigation, Pagination, EffectCoverflow]
    },
    {
      'file': news,
      'dependencies': [app],
      'resolve': '#page-news-archive'
    },
    {
      'file': contact,
      'dependencies': [app],
      'resolve': '#page-contact'
    },
    {
      'file': giftcard,
      'dependencies': [app],
      'resolve': '#page-giftcards-single'
    },
    {
      'file': actu,
      'dependencies': [app, validate],
      'resolve': '#page-news-single'
    }
    ,
    {
      'file': offre,
      'dependencies': [app],
      'resolve': '#page-offers-single'
    },
    {
      'file': nice,
      'dependencies': [app],
      'resolve': '#page-nice-single'
    },
    {
      'file': Header,
      'dependencies': [app],
      'resolve': 'header#header'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

