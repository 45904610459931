/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, gsap, Swiper, Navigation, Pagination, EffectCoverflow) => {
		/*
		|
		| Constants
		|-----------
		*/
        const
            $body         = $('body')
		;


        $body.on('loaderEnd', () => console.log('ended'))

		/*
		|
		| Sliders
		|-----------
		*/
		const swiper = new Swiper('.swiper', {
			modules: [Navigation, Pagination, EffectCoverflow],
			effect: "coverflow",
			loop: true,
			centeredSlides: true,
			slidesPerView: 1,
			breakpoints: {
				640: {
				  slidesPerView: 2
				}
			},
			coverflowEffect: {
				rotate: 0,
				stretch: 0,
				depth: 100,
				modifier: 1,
				slideShadows: true
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			pagination: {
				el: ".swiper-pagination",
				clickable: true
			},
			on: {
				click: function(){
					let slide = this.clickedSlide
					let bg = slide.children[0].dataset.src
					$('#popup-image img').attr('src', bg)
					$('#container-popup').css('display', 'block')
                }
			},
		  });

		/*
		|
		| Sliders Simple
		|-----------------
		*/
		const swiperSimple = new Swiper('.swiper-simple', {
			modules: [Navigation],
			grabCursor: true,
			loop: true,
			slidesPerView: 1,
			spaceBetween: 30,
			breakpoints: {
				540: {
				  slidesPerView: 2
				}
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		  });

		/*
		|
		| Sliders Pagintation
		|-----------------
		*/
		const swiperPagination = new Swiper('.swiper-pag', {
			modules: [Navigation, Pagination],
			loop: true,
			centeredSlides: false,
			slidesPerView: 1,
			spaceBetween: 25,
			breakpoints: {
				640: {
				  	slidesPerView: 2,
					  centeredSlides: false,
				},
				767: {
					centeredSlides: true,
					slidesPerView: 3
				  }
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			pagination: {
				el: ".swiper-pagination",
				clickable: true
			},
			on: {
				click: function(){
					let slide = this.clickedSlide
					let bg = slide.children[0].dataset.src
					$('#popup-image img').attr('src', bg)
					$('#container-popup').css('display', 'block')
                }
			},
		  });

		/**
		 * 
		 * Accordion
		 * 
		 */
		 let groups = gsap.utils.toArray(".accordion-group");
		 let menus = gsap.utils.toArray(".accordion-menu");
		 let menuToggles = groups.map(createAnimation);
 
		 menus.forEach((menu) => {
			 menu.addEventListener("click", () => toggleMenu(menu));
		 });
 
		 function toggleMenu(clickedMenu) {
			menuToggles.forEach((toggleFn) => toggleFn(clickedMenu));
		 }
 
		 function createAnimation(element) {
			 let menu = element.querySelector(".accordion-menu");
			 let box = element.querySelector(".accordion-content");
			 let icon = element.querySelector(".vertical");
 
			 gsap.set(box, { height: "auto" });
 
			 let animation = gsap
				 .from(box, {
					 height: 0,
					 duration: 0.5,
					 ease: "power1.inOut",
				 })
				 .reverse();
 
			 return function (clickedMenu) {
				 if (clickedMenu === menu) {
					 animation.reversed(!animation.reversed());
					 animation.reversed()
						 ? gsap.to(icon, { rotation: -90 })
						 : gsap.to(icon, { rotation: 90 });
				 } else {
					 animation.reverse()
					 ? gsap.to(icon, { rotation: -90 })
					 : gsap.to(icon, { rotation: 90 });;
				 }
			 };
		 }

		 /**
         * 
         * Smooth Scroll To Anchor
         *  
         */
		function scrollToAnchor(aid){
            const destination = $(aid);
            $('html,body').animate({
                scrollTop: destination.offset().top - 250
            },'slow');
		}
		$(document).on('click', '.smooth-link', function(){
			let href = $(this).attr('href');
			scrollToAnchor(href);
		});


		/**
		 * 
		 * Mobile Header
		 * 
		 */
		 let navGroups = gsap.utils.toArray(".navdrop-group");
		 let navMenus = gsap.utils.toArray(".navdrop-menu");
		 let navMenuToggles = navGroups.map(createAnimationNav);
 
		 navMenus.forEach((menu) => {
			 menu.addEventListener("click", () => toggleMenuNav(menu));
		 });
 
		 function toggleMenuNav(clickedMenu) {
			navMenuToggles.forEach((toggleFn) => toggleFn(clickedMenu));
		 }
 
		 function createAnimationNav(element) {
			 let menu = element.querySelector(".navdrop-menu");
			 let box = element.querySelector(".nav-drop");
			 let icon = element.querySelector(".vertical");
 
			 gsap.set(box, { height: "auto" });
 
			 let animation = gsap
				 .from(box, {
					 height: 0,
					 duration: 0.5,
					 ease: "power1.inOut",
				 })
				 .reverse();
 
			 return function (clickedMenu) {
				 if (clickedMenu === menu) {
					 animation.reversed(!animation.reversed());
					 animation.reversed()
						 ? gsap.to(icon, { rotation: -90 })
						 : gsap.to(icon, { rotation: 90 });
				 } else {
					 animation.reverse()
					 ? gsap.to(icon, { rotation: -90 })
					 : gsap.to(icon, { rotation: 90 });;
				 }
			 };
		 }
		 
		/**
		 * POPUP IMAGE
		 */
		$('#container-popup').click(() => {
			$('#container-popup').css('display', 'none')
			$('#popup-image img').attr('src', '')
		})
		$('#close').click(() => {
			$('#container-popup').css('display', 'none')
			$('#popup-image img').attr('src', '')
		})
	}
}
