/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, validate) => {

        let authorMsg  = $('#author').data('msg'),
            emailMsg   = $('#email').data('msg'),
            commentMsg = $('#comment').data('msg'),
            commentConf = $('#wait_approval'),
            confMessage = $('#comment-text').html()

        commentConf.text(confMessage)

        $('#commentform').validate({

            rules: {
              author: {
                required: true,
                minlength: 2
              },
        
              email: {
                required: true,
                email: true
              },
        
              comment: {
                required: true,
                minlength: 20
              }
            },
        
            messages: {
              author: authorMsg,
              email: emailMsg,
              comment: commentMsg
            },
        
            errorElement: "div",
            errorPlacement: function(error, element) {
              element.after(error);
            }
        
        });

        if(commentConf.text() !== '') {
          $('#respond').css('display', 'none')
        }

    }
}
